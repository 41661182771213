import React, { useContext, useRef, useEffect } from "react"
import { ContextDispatch, ContextState } from "../../context/Context"
import { gsap } from "gsap"
// import PropTypes from "prop-types"
// import { isMobile } from "react-device-detect"

// import ScrollSection from "../locomotiveScroll/scrollSection"

import styles from "./swipe.module.scss"

// Components
// import Nav from "./../nav/nav"

// SVG
// import Logo from "./../../../static/images/svg/logo.inline.svg"

const Swipe = props => {
  const store = useContext(ContextState)
  const storeDispatch = useContext(ContextDispatch)

  const swipe = useRef()

  useEffect(() => {
    const triggerReset = () => {
      storeDispatch({ type: "SWIPE_RESET" })
    }

    const resetSwipe = () => {
      // gsap.set(swipe.current, { x: "100%" })
      gsap.set(swipe.current, {
        opacity: 0,
      })
    }

    const swipeIn = () => {
      gsap.to(swipe.current, {
        // x: 0,
        opacity: 1,
        duration: 0.5,
        ease: ` cubic-bezier(0.84, 0.00, 0.16, 1.00)`,
        onComplete: swipeCheck,
      })
    }

    const swipeCheck = () => {
      setTimeout(() => {
        if (store.swipeState !== 0) triggerReset()
      }, 10000)
    }

    const swipeOut = () => {
      gsap.to(swipe.current, {
        // x: "-100%",
        opacity: 0,
        duration: 0.5,
        delay: 0.3,
        ease: ` cubic-bezier(0.84, 0.00, 0.16, 1.00)`,
        onComplete: triggerReset,
      })
    }
    // Reset the swipe position
    if (store.swipeState === 0) resetSwipe()

    // Swipe across the screen
    if (store.swipeState === 1) swipeIn()

    // Swipe off the screen
    if (store.swipeState === 2) swipeOut()

    return () => {}
  }, [store.swipeState, storeDispatch])

  return <div className={styles.swipe} ref={swipe}></div>
}

export default Swipe
