// import { useContext, useEffect } from "react"

// // We are excluding this from loading at build time in gatsby-node.js
// import LocomotiveScroll from "locomotive-scroll"

// import { scroll } from "./../../theme"

// const Scroll = () => {
//   const store = useContext(ContextState)

//   useEffect(() => {
//     let locomotiveScroll

//     locomotiveScroll = new LocomotiveScroll({
//       el: document.querySelector(scroll.container),
//       ...scroll.options,
//     })

//     window.scroll = locomotiveScroll
//     locomotiveScroll.stop()
//     update()

//     locomotiveScroll.on("scroll", func => {
//       document.documentElement.setAttribute("data-direction", func.direction)
//     })

//     setTimeout(begin, 500)

//     return () => {
//       if (window.scroll.smooth) {
//         window.scroll.destroy()
//       }
//     }
//   }, [store.currentPage])

//   const begin = () => {
//     update()
//     window.scroll.start()
//   }

//   const update = () => {
//     window.scroll.update()
//   }

//   return null
// }

// export default Scroll

import { useContext, useEffect } from "react"
// We are excluding this from loading at build time in gatsby-node.js
import { scroll } from "./../../theme"
import { ContextDispatch } from "./../../context/Context"
import LocomotiveScroll from "locomotive-scroll"

const Scroll = callbacks => {
  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    // console.log("reset")

    const pageChangeTimeout = setTimeout(() => {
      let locomotiveScroll
      locomotiveScroll = new LocomotiveScroll({
        el: document.querySelector(scroll.container),
        ...scroll.options,
      })
      window.scroll = locomotiveScroll
      window.scroll.stop()
      storeDispatch({ type: "LOCOMOTIVE_ON" })
    }, 405)

    const timeout = setTimeout(() => {
      window.scroll.update()
      window.scroll.start()

      window.scroll.on("scroll", args => {
        document.documentElement.setAttribute("data-direction", args.direction)
      })
    }, 1600)

    return () => {
      if (pageChangeTimeout) {
        clearTimeout(pageChangeTimeout)
      }
      if (timeout) {
        clearTimeout(timeout)
      }

      if (window.scroll) {
        window.scroll.destroy()
      }
    }
  }, [callbacks.callbacks.pathname])

  return null
}

export default Scroll
