import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import "raf/polyfill"
import "@babel/polyfill"
import React from "react"
import Context from "./src/context/Context"

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   if (prevLocation) {
//     setTimeout(() => {
//       if (window?.scroll?.update) {
//         console.log("here")
//         window.scroll.update()
//       }
//     }, 750)
//   }
// }

export const wrapRootElement = ({ element }) => <Context>{element}</Context>
