import React, { useContext, useRef, useEffect } from "react"
import { ContextDispatch, ContextState } from "../../context/Context"
import { gsap } from "gsap"

import styles from "./loader.module.scss"

// SVG
import Circle from "./../../../static/images/svg/circle.inline.svg"

const Loader = props => {
  const loader = useRef(),
    circle = useRef()

  const store = useContext(ContextState)
  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const loadingFunction = () => {
      const { current } = circle,
        svg = current.querySelector("svg"),
        circleShape = svg.querySelector("circle")

      gsap.to(circleShape, {
        strokeDashoffset: 0,
        opacity: 0.5,
        duration: 1,
        ease: ` cubic-bezier(0.76, 0, 0.24, 1)`,
        onComplete: closeLoader,
      })
    }

    const closeLoader = () => {
      const { current } = loader
      current.setAttribute("data-closed", true)
      storeDispatch({ type: "LOADER_OFF" })
    }

    // Reset the swipe position
    if (store.loader === true) {
      loader.current.setAttribute("data-closed", false)
      loadingFunction()
    }
  }, [store.loader, storeDispatch])

  return (
    <div className={styles.loader} ref={loader} data-closed="true">
      <span className={styles.loaderCircle} ref={circle}>
        <Circle />
      </span>

      <span className={styles.loaderPercentage}>Loading</span>
    </div>
  )
}

export default Loader
