// Core
import React, { useState, useEffect, useContext } from 'react'
import TransitionLink from "gatsby-plugin-transition-link"
import { ContextDispatch } from "./../../context/Context"

// Styles
import styles from "./cookie-bar.module.scss"

const CookieBar = () => {
  const cookieKey = 'gatsby-gdpr-google-analytics'
  const [show, setShow] = useState(false)

  useEffect(() => {
    const cookie = document.cookie

    if (cookie.length === 0) {
      setShow(true)
    } else {
      let accepted = cookie
        .split('; ')
        .find(row => row.startsWith(`${cookieKey}=`))

      if (accepted) {
        accepted = accepted.split('=')[1]
      }

      if (accepted === 'true') {
        setShow(false)
      } else {
        setShow(true)
      }
    }
  }, [])

  const enableCookies = () => {
    document.cookie = `${cookieKey}=true;path=/`
    setShow(false)
  }

  const storeDispatch = useContext(ContextDispatch)

  const exitAnim = (exit, node) => {
    storeDispatch({ type: "SWIPE_IN" })
  }

  const entryAnim = (entry, node) => {
    storeDispatch({ type: "SWIPE_OUT" })
  }

  return (
    <div className={`${styles.cookieBar} ${show ? styles.visible : styles.hidden}`}>
      <p>
        By using this website, you agree to{' '}
        <TransitionLink
          to={`/privacy-policy`}
          exit={{
            trigger: ({ exit, node }) => exitAnim(exit, node),
            length: 0.6,
            zIndex: 2,
          }}
          entry={{
            trigger: ({ entry, node }) => entryAnim(entry, node),
            delay: 1,
            length: 1,
            zIndex: 1,
          }}
        >
          our use of cookies
        </TransitionLink>
        .
        <button className={styles.btn} onClick={enableCookies}>
          <span>OK, got it</span>
        </button>
      </p>
    </div>
  )
}

export default CookieBar
