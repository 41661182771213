// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-insights-page-js": () => import("./../../../src/templates/insights/page.js" /* webpackChunkName: "component---src-templates-insights-page-js" */),
  "component---src-templates-insights-slug-page-js": () => import("./../../../src/templates/insights-slug/page.js" /* webpackChunkName: "component---src-templates-insights-slug-page-js" */),
  "component---src-templates-press-page-js": () => import("./../../../src/templates/press/page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-template-1-new-page-js": () => import("./../../../src/templates/template-1-new/page.js" /* webpackChunkName: "component---src-templates-template-1-new-page-js" */),
  "component---src-templates-template-2-page-js": () => import("./../../../src/templates/template-2/page.js" /* webpackChunkName: "component---src-templates-template-2-page-js" */),
  "component---src-templates-template-3-new-page-js": () => import("./../../../src/templates/template-3-new/page.js" /* webpackChunkName: "component---src-templates-template-3-new-page-js" */),
  "component---src-templates-template-4-new-page-js": () => import("./../../../src/templates/template-4-new/page.js" /* webpackChunkName: "component---src-templates-template-4-new-page-js" */),
  "component---src-templates-template-4-page-js": () => import("./../../../src/templates/template-4/page.js" /* webpackChunkName: "component---src-templates-template-4-page-js" */),
  "component---src-templates-template-5-page-js": () => import("./../../../src/templates/template-5/page.js" /* webpackChunkName: "component---src-templates-template-5-page-js" */),
  "component---src-templates-template-6-page-js": () => import("./../../../src/templates/template-6/page.js" /* webpackChunkName: "component---src-templates-template-6-page-js" */),
  "component---src-templates-template-profile-page-js": () => import("./../../../src/templates/template-profile/page.js" /* webpackChunkName: "component---src-templates-template-profile-page-js" */),
  "component---src-templates-text-page-js": () => import("./../../../src/templates/text/page.js" /* webpackChunkName: "component---src-templates-text-page-js" */)
}

