import React, { useState, useRef, useEffect, useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"

import ScrollSection from "../locomotiveScroll/scrollSection"

// InView detector
import "intersection-observer"
import { InView } from "react-intersection-observer"

import styles from "./header.module.scss"

// Components
import { ContextDispatch, ContextState } from "./../../context/Context"
import Nav from "./../nav/nav"

// SVG
import Logo from "./../../../static/images/svg/logo.inline.svg"

const Header = props => {
  const { open } = props,
    { setContactOpen } = props //for contact modal

  const storeState = useContext(ContextState)

  const [menuOpen, setMenuOpen] = useState(false)

  const menuButton = useRef()

  const storeDispatch = useContext(ContextDispatch)

  const exitAnim = (exit, node) => {
    storeDispatch({ type: "SWIPE_IN" })
  }

  const entryAnim = (entry, node) => {
    storeDispatch({ type: "SWIPE_OUT" })
    if (isMobile && menuOpen === false) {
      setMenuOpen(false)
      document.querySelector("body").style.overflow = "auto"
    }
  }

  useEffect(() => {
    const { current } = menuButton

    const toggleMenu = () => {
      setMenuOpen(true)
      if (isMobile) {
        document.querySelector("body").style.overflow = "hidden"
      }
    }

    if (menuOpen === false) {
      if (document.querySelector("body")) {
        if (document.querySelector("body").classList.contains("no-scroll")) {
          document.querySelector("body").style.overflow = "auto"
        }
      }
    }

    if (!isMobile && menuOpen === true) {
      setMenuOpen(false)
      document.querySelector("body").style.overflow = "auto"
    }

    if (!current) return
    current.addEventListener("click", toggleMenu)

    return () => {
      if (!current) return
      current.removeEventListener("click", toggleMenu)
    }
  }, [menuOpen])

  return (
    <ScrollSection>
      <InView triggerOnce={true}>
        {({ inView, ref, entry }) => (
          <header
            className={`${styles.header}`}
            ref={ref}
            inview={inView.toString()}
            data-open={menuOpen}
            data-theme={storeState.headerTheme}
          >
            <div className={`logo ${styles.logo}`}>
              <TransitionLink
                to={`/`}
                exit={{
                  trigger: ({ exit, node }) => exitAnim(exit, node),
                  length: 1,
                  zIndex: 2,
                }}
                entry={{
                  trigger: ({ entry, node }) => entryAnim(entry, node),
                  delay: 1,
                  length: 1,
                  zIndex: 1,
                }}
              >
                <Logo />
              </TransitionLink>
            </div>
            <button
              className={`menuBtn ${styles.menuBtn}`}
              ref={menuButton}
              aria-label="Open / Close Menu"
            >
              <span></span>
              <span></span>
            </button>
            <div className={styles.menuWrap}>
              <Nav
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                setContactOpen={setContactOpen}
                contactOpen={open}
              />
            </div>
          </header>
        )}
      </InView>
    </ScrollSection>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  theme: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
