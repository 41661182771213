// Core
import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import "raf/polyfill"
import "@babel/polyfill"
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"

//Grid
import Grid from "../_grid/grid"

// Components
import { ContextDispatch, ContextState } from "./../../context/Context"
import Contact from "./../contact-modal/contact"
import Loader from "./../loader/loader"
import Swipe from "./../swipe/swipe"
import Header from "./../header/header"
import Scroll from "./../locomotiveScroll"
import SEO from "./../seo/seo"
import CookieBar from "./../cookie-bar/cookie-bar"

// Styles
import "./base.scss"
import "./../locomotiveScroll/locomotive-scroll.css"

const Layout = ({ children, location, scroll = true, headerTheme }) => {
  const [contactOpen, setContactOpen] = useState(false)

  const storeDispatch = useContext(ContextDispatch)
  const store = useContext(ContextState)

  // Loader, only shown on first page visit
  useEffect(() => {
    if (store.currentPage === 0) {
      const loader = () => {
        storeDispatch({ type: "LOADER_ON" })
      }
      loader()
    }
  }, [storeDispatch, store.currentPage])

  const keyMode = useRef(false)

  const setMouseMode = useCallback(() => {
    if (!keyMode.current) return
    keyMode.current = false
    storeDispatch({ type: "KEY_ACCESS_OFF" })
    if (document.querySelector("body")) {
      document.querySelector("body").classList.remove("key-access")
    }
  }, [storeDispatch])

  const checkNavKey = key => {
    return key === 9 || key === 35 || key === 36 || key === 38 || key === 40
  }

  const setKeyboardMode = useCallback(
    e => {
      if (keyMode.current) return
      if (!checkNavKey(e.keyCode)) return
      keyMode.current = true
      storeDispatch({ type: "KEY_ACCESS_ON" })
      if (document.querySelector("body")) {
        document.querySelector("body").classList.add("key-access")
      }
    },
    [storeDispatch]
  )

  useEffect(() => {
    document.querySelector("body").addEventListener("mousedown", setMouseMode)
    document.querySelector("body").addEventListener("keydown", setKeyboardMode)

    return () => {
      document
        .querySelector("body")
        .removeEventListener("mousedown", setMouseMode)
      document
        .querySelector("body")
        .removeEventListener("keydown", setKeyboardMode)
    }
  }, [setKeyboardMode, setMouseMode])

  return (
    <React.Fragment>
      <SEO />
      <Grid />
      <Swipe />
      <Loader />
      <Contact open={contactOpen} setContactOpen={setContactOpen} />
      {!isMobile && <Scroll callbacks={location} />}
      <div className="site-wrap" data-scroll-container>
        <Header
          location={location}
          headerTheme={headerTheme}
          open={contactOpen}
          setContactOpen={setContactOpen}
        />
        <main>{children}</main>
      </div>
      <CookieBar />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
