import React, { useReducer, createContext } from "react"

export const ContextState = createContext()
export const ContextDispatch = createContext()

const initialState = {
  // 0 = right of the page, pre-swipe
  // 1 = swiped across page, mid-swipe
  // 2 = left of the page, post-swipe
  swipeState: 0,
  currentPage: 0,
  headerTheme: "light",
  loader: false,
  keyAccess: false,
  locomotiveInit: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SWIPE_RESET": {
      return {
        ...state,
        swipeState: 0,
      }
    }
    case "SWIPE_IN": {
      return {
        ...state,
        swipeState: 1,
      }
    }
    case "SWIPE_OUT": {
      return {
        ...state,
        swipeState: 2,
        currentPage: state.currentPage + 1,
      }
    }
    case "HEADER_LIGHT": {
      return {
        ...state,
        headerTheme: "light",
      }
    }
    case "HEADER_DARK": {
      return {
        ...state,
        headerTheme: "dark",
      }
    }
    case "LOADER_ON": {
      return {
        ...state,
        loader: true,
      }
    }
    case "LOADER_OFF": {
      return {
        ...state,
        loader: false,
      }
    }
    case "KEY_ACCESS_ON": {
      return {
        ...state,
        keyAccess: true,
      }
    }
    case "KEY_ACCESS_OFF": {
      return {
        ...state,
        keyAccess: false,
      }
    }
    case "LOCOMOTIVE_ON": {
      return {
        ...state,
        locomotiveInit: true,
      }
    }
    case "LOCOMOTIVE_OFF": {
      return {
        ...state,
        locomotiveInit: false,
      }
    }

    default:
      throw new Error("Bad action type")
  }
}

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ContextState.Provider value={state}>
      <ContextDispatch.Provider value={dispatch}>
        {children}
      </ContextDispatch.Provider>
    </ContextState.Provider>
  )
}

export default Context
