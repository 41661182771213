export const scroll = {
  // Locomotive Scroll
  // https://github.com/locomotivemtl/locomotive-scroll#instance-options
  container: '.site-wrap',
  options: {
    smooth: true,
    smoothMobile: false,
    direction: 'vertical',
    getDirection: true,
    touchMultiplier: 2.5,
    firefoxMultiplier: 70,
    lerp: 0.1,
    repeat: false,
    class: 'is-inview',
    reloadOnContextChange: true,
  },
}