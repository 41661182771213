import { StaticQuery } from "gatsby"
import React, { useRef, useEffect, useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import debounce from "lodash.debounce"

import { ContextDispatch } from "./../../context/Context"
import { isMobile } from "react-device-detect"
import styles from "./nav.module.scss"

// SVG
import Logo from "./../../../static/images/svg/logo.inline.svg"
// import Twitter from "./../../../static/images/svg/twitter.inline.svg"
import LinkedIn from "./../../../static/images/svg/linkedin.inline.svg"
// import Instagram from "./../../../static/images/svg/instagram.inline.svg"
// import Medium from "./../../../static/images/svg/medium.inline.svg"

const Nav = props => {
  const storeDispatch = useContext(ContextDispatch)

  const { menuOpen } = props,
    { setMenuOpen } = props,
    { open } = props,
    { setContactOpen } = props

  const menuButton = useRef()

  useEffect(() => {
    const { current } = menuButton

    const toggleMenu = () => {
      setMenuOpen(false)
      if (window.innerWidth < 1240) {
        document.querySelector("body").style.overflow = "auto"
      }
    }

    if (!isMobile && menuOpen === true) {
      setMenuOpen(false)
    }

    if (!current) return
    current.addEventListener("click", toggleMenu)

    if (window) {
      window.addEventListener("resize", debounce(toggleMenu, 500))
    }

    return () => {
      if (!current) return
      current.removeEventListener("click", toggleMenu)

      if (window) {
        window.removeEventListener("resize", debounce(toggleMenu, 500))
      }
    }
  }, [menuOpen, setMenuOpen])

  // let insta, twitter, linked, medium

  const exitAnim = (exit, node) => {
    setMenuOpen(false)
    if (window.innerWidth < 1240) {
      document.querySelector("body").style.overflow = "auto"
    }
    storeDispatch({ type: "SWIPE_IN" })
  }

  const entryAnim = (entry, node) => {
    storeDispatch({ type: "SWIPE_OUT" })
  }

  return (
    <StaticQuery
      query={graphql`
        query MainNavQuery {
          allContentfulMainMenu {
            edges {
              node {
                title
                menuItem {
                  linkTitle
                  linkDestination {
                    ... on ContentfulTemplate1 {
                      slug
                    }
                    ... on ContentfulTemplate2 {
                      slug
                    }
                    ... on ContentfulTemplate3 {
                      slug
                    }
                    ... on ContentfulTemplate4 {
                      slug
                    }
                    ... on ContentfulTemplate5 {
                      slug
                    }
                    ... on ContentfulTemplate6 {
                      slug
                    }
                    ... on ContentfulHeathcareAiPage {
                      slug
                    }
                    ... on ContentfulPressPage {
                      slug
                    }
                    ... on ContentfulTextOnlyTemplate {
                      slug
                    }
                    ... on ContentfulInsightsPage {
                      slug
                    }
                  }
                }
              }
            }
          }
          allContentfulContactModal {
            edges {
              node {
                heading
                contactEmailAddress
                address
                instagramUsername
                linkedInUrl
                mediumUrl
                twitterUsername
                contentful_id
              }
            }
          }
        }
      `}
      render={data => (
        <nav className={styles.nav}>
          <div className={`logo ${styles.logo}`}>
            <TransitionLink
              title={"Homepage"}
              to={`/`}
              className={`class`}
              exit={{
                trigger: ({ exit, node }) => exitAnim(exit, node),
                length: 0.6,
                zIndex: 2,
              }}
              entry={{
                trigger: ({ entry, node }) => entryAnim(entry, node),
                delay: 1,
                length: 1,
                zIndex: 1,
              }}
            >
              <Logo />
            </TransitionLink>
          </div>
          <button
            className={`menuBtn ${styles.menuBtn}`}
            ref={menuButton}
            aria-label="Open / Close Menu"
          >
            <span></span>
            <span></span>
          </button>
          <div className={styles.centerSection}>
            <ul className={styles.mainLinks}>
              <li className={`link ${styles.link} ${styles.homeLink}`}>
                <TransitionLink
                  to={`/`}
                  exit={{
                    trigger: ({ exit, node }) => exitAnim(exit, node),
                    length: 0.6,
                    zIndex: 2,
                  }}
                  entry={{
                    trigger: ({ entry, node }) => entryAnim(entry, node),
                    delay: 1,
                    length: 1,
                    zIndex: 1,
                  }}
                  activeClassName={styles.activelink}
                >
                  Home
                </TransitionLink>
              </li>
              {data.allContentfulMainMenu.edges[0].node.menuItem.map(
                (item, index) => {
                  return (
                    <li className={`link ${styles.link}`} key={index}>
                      {item?.linkDestination?.slug ? (
                        <TransitionLink
                          title={item.linkTitle}
                          to={`/${item?.linkDestination?.slug}`}
                          exit={{
                            trigger: ({ exit, node }) => exitAnim(exit, node),
                            length: 0.6,
                            zIndex: 2,
                          }}
                          entry={{
                            trigger: ({ entry, node }) =>
                              entryAnim(entry, node),
                            delay: 1,
                            length: 1,
                            zIndex: 1,
                          }}
                          activeClassName={styles.activelink}
                          partiallyActive={true}
                        >
                          {item.linkTitle}
                        </TransitionLink>
                      ) : (
                        ``
                      )}
                    </li>
                  )
                }
              )}
            </ul>
            <a
              title="Contact"
              href="/"
              className={`link ${styles.contactlink}`}
              onClick={e => {
                e.preventDefault()
                setContactOpen(!open)
              }}
            >
              Contact
            </a>
          </div>
          <div className={styles.socialMedia}>
            <ul className={styles.socialMediaList}>
              <li>
                <a
                  title={`Aegis Labs' LinkedIn`}
                  href={
                    data.allContentfulContactModal.edges[0].node.linkedInUrl
                  }
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Aegis Labs' LinkedIn`}
                >
                  <LinkedIn />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      )}
    />
  )
}

export default Nav
