import React from "react"
import PropTypes from "prop-types"

// import { isMobile } from "react-device-detect"

const ScrollSection = ({ events, children, zindex }) => {
  const baseStyles = {
    zIndex: zindex ? zindex : null,
    position: zindex ? "relative" : null,
  }

  return (
    <div data-scroll-section style={baseStyles}>
      {children}
    </div>
  )
}

ScrollSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScrollSection
